import { render, staticRenderFns } from "./DocumentOcrPages.vue?vue&type=template&id=73f7fda8&scoped=true&"
import script from "./DocumentOcrPages.vue?vue&type=script&lang=js&"
export * from "./DocumentOcrPages.vue?vue&type=script&lang=js&"
import style0 from "./DocumentOcrPages.vue?vue&type=style&index=0&id=73f7fda8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73f7fda8",
  null
  
)

export default component.exports