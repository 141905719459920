<template>
  <main>
    <section class="modal-bounds vld-parent">
      <header class="modal-title">
        Thumbnail Manager
      </header>
      <Loading
        class="vasion-loading-indicator"
        :active.sync="isLoading"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
      <div class="thumbnails">
        <div
          v-for="(page, index) in pages"
          :key="page.number"
          class="thumbnail-container"
          @click="pageSelected = index"
        >
          <div :class="{ 'border-red': page.delete }">
            <div class="thumbnail-border">
              <ThumbnailImage
                ref="mainImage"
                class="min-height shadow"
                :class="{ 'rotate-90': page.rotation === 1, 'rotate-180': page.rotation === 2, 'rotate-270': page.rotation === 3 }"
                :documentID="documentId"
                :pageNumber="page.number"
                :width="120"
                :index="index"
                :imageString="page.imageString"
              />
            </div>
            <div class="thumbnail-footer">
              <div id="thumbnail-page-number">
                Page {{ index + 1 }}
              </div>
              <VasionButton
                :isRaised="false"
                :isDense="false"
                :icon="'VasionMoreVert'"
                @vasionButtonClicked="openEllipsisMenu($event, index)"
              />
            </div>
          </div>
          <div class="thumbnail-controls">
            <VasionButton
              v-if="pageSelected === index"
              id="move-left-button"
              title="Move page left"
              name="move-left-button"
              icon="VasionArrowLeftIcon"
              :classProp="'secondary-grey'"
              @vasionButtonClicked.stop="movePageLeftOrRight(index, index - 1)"
            />
            <VasionInput
              v-if="pageSelected === index"
              id="move-to-page-input"
              v-model="moveTo"
              name="move-to-page-input"
              type="text"
              inputmode="numeric"
              onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
              inputType="blank-white"
              width="36"
              :placeholder="index + 1"
            />
            <VasionButton
              v-if="pageSelected === index"
              id="move-submit-button"
              name="move-submit-button"
              title="Move page to input"
              icon="VasionCheck"
              :classProp="'secondary-grey'"
              @vasionButtonClicked.stop="movePage(index, moveTo)"
            />
            <VasionButton
              v-if="pageSelected === index"
              id="move-right-button"
              name="move-right-button"
              title="Move page right"
              icon="VasionArrowRightIcon"
              :classProp="'secondary-grey'"
              @vasionButtonClicked.stop="movePageLeftOrRight(index, index + 1)"
            />
          </div>
        </div>
      </div>
    </section>
    <footer>
      <VasionButton
        id="btnFolderBrowserCancel"
        classProp="secondary"
        :isRaised="false"
        @vasionButtonClicked="cancel()"
      >
        Cancel
      </VasionButton>
      <VasionButton
        id="btnFolderBrowserOK"
        classProp="primary"
        :isDisabled="disableSaveButton"
        :isRaised="false"
        @vasionButtonClicked="save()"
      >
        Save
      </VasionButton>
    </footer>
    <VasionEllipsisMenu
      v-if="showThumbnailMenu"
      :buttonConfig="ellipsisButtonConfig"
      :style="ellipsisMenuStyle"
      :rowItem="ellipsisRowItem"
      @ellipsis-button-clicked="ellipsisButtonClicked"
    />
    <VasionGeneralModal
      id="vasion-setup-instructions"
      :hideButtons="true"
      :modalType="'slot'"
      buttonGroup="single"
      :sync="showLeaveDialog"
      @noButtonClick="showLeaveDialog = false"
    >
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="showLeaveDialog = false"
        @yesButtonClick="$emit('cancel')"
      />
    </VasionGeneralModal>
  </main>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import ThumbnailImage from '@/components/document/ThumbnailImage.vue';

export default {
  name: 'ThumbnailManager',
  components: {
    Loading,
    ThumbnailImage,
  },
  props: {
    documentId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data: function () {
    return {
      documentDetails: {},
      ellipsisButtonConfig: {
        buttons: [
          {
            id: 'rotate-left',
            name: 'Rotate Left',
            icon: 'VasionRotateLeftIcon',
          },
          {
            id: 'rotate-right',
            name: 'Rotate Right',
            icon: 'VasionBeginRepeatIcon',
          },
          {
            id: 'delete-page',
            name: 'Delete Page',
            icon: 'VasionDeleteIcon',
          },
        ],
      },
      ellipsisRowItem: null,
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      moveTo: '',
      pageCount: 0,
      pages: [],
      pageSelected: 0,
      showLeaveDialog: false,
      showThumbnailMenu: false,
    }
  },
  computed: {
    disableSaveButton() { return this.pages.filter(p => p.delete === false).length === 0 },
  },
  async created() {
    this.isLoading = true
    this.documentDetails = await this.$store.dispatch('document/getDocumentDetails', { documentID: this.documentId })
    this.pageCount = this.documentDetails.FileDetail.PageCount
    this.setup()
  },
  methods: {
    cancel() {
      if (this.isDirty) {
        this.showLeaveDialog = true
      } else {
        this.$emit('cancel')
      }
    },
    clickedOutsideLeave() {
      this.routeTo = null
    },
    ellipsisButtonClicked(e) {
      this.showThumbnailMenu = false
      switch (e.buttonId) {
        case 'rotate-left':
          if (e.item.rotation <= 0) {
            e.item.rotation = 3
          } else {
            e.item.rotation -= 1
          }
          this.isDirty = true
          break;
        case 'rotate-right':
          if (e.item.rotation >= 3) {
            e.item.rotation = 0
          } else {
            e.item.rotation += 1
          }
          this.isDirty = true
          break;
        case 'delete-page':
          e.item.delete = !e.item.delete
          this.isDirty = true
          break;
        default:
          break;
      }
    },
    movePage(index, toIndex) {
        if (index === '' || toIndex === '') {
          return
        }
        this.isDirty = true
        const left = this.pages.slice(0, index)
        const middle = this.pages.slice(index + 1, toIndex)
        const right = this.pages.slice(toIndex)
        this.pages = left.concat(middle, this.pages[index], right)
        this.moveTo = ''
    },
    movePageLeftOrRight(index, toIndex) {
      this.isDirty = true
      if (toIndex < 0) {
        toIndex = this.pageCount
      }
      if (toIndex >= this.pageCount) {
        toIndex = 0
      }
      const indexPageNumber = this.pages[index]
      this.pages[index] = this.pages[toIndex]
      this.pages[toIndex] = indexPageNumber
      this.pageSelected = toIndex
    },
    openEllipsisMenu(e, pageIndex) {
      this.ellipsisMenuStyle = `top:${e.y + 20}px; left:${e.x - 270}px; margin: 0px;`
      this.showThumbnailMenu = true
      this.ellipsisRowItem = this.pages[pageIndex]
    },
    async save() {
      if (this.disableSaveButton) {
        return
      }

      const payload = {
        ThumbnailCollection: {
          Values: this.pages.map(page => {
            let rotation = 0
            switch (page.rotation) {
              case 1:
                rotation = 90
                break;
              case 2:
                rotation = 180
                break;
              case 3:
                rotation = 270
                break;
              default:
                break;
            }
            return {
              DocumentId: this.documentId,
              PageNumber: page.number,
              PageRotation: rotation,
              RemovePage: page.delete,
              NewDocument: false,
            }
          }),
        },
      }
      this.isLoading = true
      const [, result] = await Promise.all([
        this.$store.dispatch('document/deleteDocCacheFiles', { Value: this.documentId }),
        this.$store.dispatch('document/saveThumbnailInfo', payload),
      ])
      this.isLoading = false
      this.$emit('cancel', result)
    },
    async setup() {
      const localPages = []
      let images = []
      for (let i = 0; i < this.pageCount; i += 1) {
        const payload = {
          DocumentId: this.documentId,
          PageNumber: i + 1,
          ReloadImage: false,
          ReturnImageData: true,
        }
        const image = this.$store.dispatch('document/getThumbnail', payload).then(imageString => { return imageString })
        images.push(image)
        localPages.push({
          number: i + 1,
          delete: false,
          rotation: 0,
        })
      }
      this.pages = localPages
      this.isLoading = false

      images = await Promise.all(images)
      for (let i = 0; i < this.pageCount; i += 1) {
        this.pages[i].imageString = images[i]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  main {
    width: 928px;
    height: 628px;
  }

  .min-height {
    min-height: 120px;
  }

  .modal-bounds {
    width: 928px;
    background-color: $white;
  }

  .modal-title {
    @include SubtitleActive;
    font-size: 1.75em;
  }

  footer {
    position: relative;
    bottom: 0px;
    display: flex;
    justify-content: flex-end;
  }

  .thumbnails {
    display: flex;
    flex-flow: row wrap;
    overflow-x: auto;
    height: 550px;
  }

  .thumbnail-border {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 12px 12px 0px 12px;
    background-color: #F8F9F9;
    border: 1px solid $grey-100;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    height: 192px;
  }

  .thumbnail-container {
    margin: 16px;
    flex: 0 0 21%;
  }

  .thumbnail-footer {
      height: 40px;
      background-color: white;
      border-bottom: 1px solid $grey-100;
      border-left: 1px solid $grey-100;
      border-right: 1px solid $grey-100;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  #thumbnail-page-number {
    margin-left: 12px;
  }

  .shadow {
    box-shadow: 1px 0px 5px 1px $grey-100;
  }

  .thumbnail-menu{
    position: relative;
    width: 280px;
    height: 160px;
    padding: 10px 0;
    background-color: white;
    border: 1px solid black;
    z-index: 5;
  }

  .thumbnail-controls{
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .border-red {
    border: 1px solid red;
    border-radius: 4px;
  }

  .rotate-90 {
    transform: rotate(90deg);
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  .rotate-270 {
    transform: rotate(270deg);
  }

</style>
